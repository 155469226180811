import axios from '../AxiosInstance';

export const GetMachineData = async () => {
    let resolved = {
        data: null,
        error: null
    }
    try {
        const response = await axios.post(`/machineget`)
        resolved.data = response.data
    } catch (error) {
        resolved.error = "Your Internet is not Connected"
    }
    return resolved
}