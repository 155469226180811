import React from 'react'
import { Line } from 'react-chartjs-2';
import './AreaChart.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function AreaChart(props) {

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'left',
                display : false,
            },
        },
        scales: {
            x: {
                ticks: {
                  font: {
                    size: 11,
                    // family: "vazir",
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    size: 11,
                    // family: "",
                  },
                },
                beginAtZero: true,
                // max: 100,
                // max: props.maxValueArea,
              },
        }

    };
    const labels = props.labels
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: props.flowlabel,
                data: props.flow,
                borderColor: 'rgb(128,128,128)',
                backgroundColor: 'rgba(128,128,128,0.5)',
                radius: 3,
                borderJoinStyle: 'round',
            },
            
        ],
    };
  return (
    <>
    <div className='linechartmain'>
    <Line options={options} data={data}/>
    {/* <Line style={{minWidth:"49vw", height:"15vh"}} options={options} data={data}/> */}
    </div>
    </>
  )
}
