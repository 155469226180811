import { DateRangePicker } from "rsuite";
import React, { useState, useEffect } from "react";
import "./MainDashboard.css";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Header from "../Common/Header/Header";
import Clock from "react-live-clock";
import { GetMeterData } from "../../Service/MeterApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActiveStatus from "./ActiveStatus/ActiveStatus";
import LinearProgress from "@mui/material/LinearProgress";

export default function MainDashboard() {
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  let MQTT_ID = new URLSearchParams(location.search).get("MQTT_ID");
  const [meterDashData, setMeterDashData] = useState([]);
  const [loading, setLoading] = useState(false);
  let machineName = meterDashData[0]?.machineName;
  let getarray = meterDashData?.map((array) => {
    return array?.meterReadings;
  });
  let getarrayHour = meterDashData?.map((array) => {
    return array?.HourReadings;
  });
  // Month Average Calculation
  let currentMonth =
    getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.datetimeMonth;
  let CurrentMonthDateFilter = meterDashData?.map((meter) => {
    return {
      ...meter,
      meterReadings: meter?.DayReadings.filter((val) => {
        let currenDate = val?.datetimeMonth;
        if (currenDate === currentMonth) {
          return true;
        } else {
          return false;
        }
      }),
    };
  });
  let readd = CurrentMonthDateFilter[0]?.meterReadings;
  let daylength = readd?.length;
  let totalthismonth = 0;
  if (readd) {
    totalthismonth = readd[readd?.length - 1]?.total - readd[0]?.total;
  }
  let AvgtotalMonth = totalthismonth / daylength;

  // this api is goes to get api of meters data
  const gettingMeterData = async () => {
    setLoading(true);
    let res = await GetMeterData(MQTT_ID);
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setMeterDashData(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (MQTT_ID) {
      // setInterval(() => {
      gettingMeterData();
      // }, 5000);
    }
  }, [MQTT_ID]);

  // the function is use to find the date between start date and last date
  const findByDateDaily = () => {
    let startDate = dateback?.startDate;
    let endDate = dateback?.endDate;
    let sDate = new Date(startDate).getTime();
    let eDate = new Date(endDate).getTime();
    let epStartDate = new Date(sDate - 18000000).getTime();
    let epEndDate = new Date(eDate + 68000000).getTime();
    let filterDate = meterDashData?.map((meter) => {
      return {
        ...meter,
        meterReadings: meter?.DayReadings.filter((val) => {
          let currenDate = new Date(val?.datetime).getTime();
          if (currenDate >= epStartDate && currenDate <= epEndDate) {
            console.log(startDate, "--------------", endDate,"_---------", moment(currenDate)?.format("YYYY-MM-DD"))
            return true;
          } else {
            return false;
          }
        }),
      };
    });

    navigate("/dashboard/report", {
      state: { date: sendDate, roomName: machineName, filterDate: filterDate },
    });
  };

  return (
    <>
      {loading === true ? (
        <div className="loader">
          <LinearProgress />
        </div>
      ) : (
        <>
          <Header
            timedate={
              <Clock
                format={"HH:mm:ss| DD-MM-YYYY"}
                ticking={true}
                timezone={"asia/Karachi"}
              />
            }
          />
          <div className="MainDashboardContainer">
            <ActiveStatus
              roomName={machineName}
              deviceID={meterDashData[0]?.Device_ID}
              datetimeActiveStatus={
                getarray[0]?.[getarray[0]?.length - 1]?.date +
                "\t | \t" +
                getarray[0]?.[getarray[0]?.length - 1]?.time
              }
              activeChartChk={
                getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.flow
              }
              activestatusLabels={getarrayHour[0]
                ?.slice(-24)
                ?.map((time) => moment(time?.datetime).format("DD |HH:mm"))}
              activestatusflow={getarrayHour[0]
                ?.slice(-24)
                ?.map((dataa) => dataa?.flow)}
              activeflowChk={getarray[0]?.[getarray[0]?.length - 1]?.flow}
              activemachineIDChk={meterDashData[0]?.machineID}
              activeDayAvgChk={getarray[0]?.[getarray[0]?.length - 1]?.total}
              activetotalthismonthChk={
                getarray[0]?.[getarray[0]?.length - 1]?.total
              }
              activetotalChk={getarray[0]?.[getarray[0]?.length - 1]?.total}
              activelinetempChk={
                getarray[0]?.[getarray[0]?.length - 1]?.linetemp
              }
              activepressureChk={
                getarray[0]?.[getarray[0]?.length - 1]?.pressure
              }
              activeflow={getarray[0]?.[getarray[0]?.length - 1]?.flow}
              activetotal={parseInt(
                getarray[0]?.[getarray[0]?.length - 1]?.total
              ).toLocaleString()}
              activeDayAvg={parseInt(AvgtotalMonth).toLocaleString()}
              activetotalthismonth={parseInt(totalthismonth).toLocaleString()}
              activelinetemp={parseInt(
                getarray[0]?.[getarray[0]?.length - 1]?.linetemp
              ).toLocaleString()}
              activepressure={parseInt(
                getarray[0]?.[getarray[0]?.length - 1]?.pressure
              ).toLocaleString()}
              dateFilter={
                <>
                  <div className="datepiker">
                    <DateRangePicker
                      showOneCalendar
                      placement="bottomStart"
                      className="rangepiker"
                      onChange={(event) => settingDate(event)}
                      value={value}
                      placeholder="Start Date ~ End Date"
                      renderValue={(value) => {
                        return (
                          moment(value[0])?.format("DD-MM-YYYY") +
                          " ~ " +
                          moment(value[1])?.format("DD-MM-YYYY")
                        );
                      }}
                    />
                    <button className="btnreport" onClick={findByDateDaily}>
                      {" "}
                      Report
                    </button>
                  </div>
                </>
              }
            />
          </div>
          <ToastContainer />
        </>
      )}
    </>
  );
}
