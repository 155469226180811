import React, { useContext,useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "react-flexible-sliding-menu";
// import { HomeSVG, DashboardSVG, GallerySVG } from "./svgs";
import './SlideableManu1.css'

import { GetMachineData } from "../../../Service/MachineApi";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CottageIcon from '@mui/icons-material/Cottage';

function Menu() {
  const { closeMenu } = useContext(MenuContext);
  const [MachineData, setMachineData] = useState([])
  const navigate = useNavigate()

  const gettingMachineData = async () => {
    let res = await GetMachineData()
    if (res.error != null) {
      // toast.error(res.error)
    } else {
      setMachineData(res.data)
    }
  }
  useEffect(() => {
    gettingMachineData()
  }, [])

  return (
    <>
      <div className="Menu">

        <nav onClick={closeMenu}>
          {
            MachineData.map((bdata, i) => {
              return (
                <div key={i} className="machineDatas" onClick={()=>{
                  navigate(`/dashboard/Dep?MQTT_ID=${bdata?.MQTT_ID}`)
                }}>
                  <span className="officesvg"><CottageIcon/></span>
                  <span className="machineNamecontainer">{bdata?.machineName}</span>
                </div>
              )
            })
          }
        </nav>

        <button onClick={closeMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <ToastContainer/>
      </div>
    </>
  );
}

export default Menu;
