import React from "react";
import "./ReportMachineHour.css";
import Header from "../Common/Header/Header";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location.state.date;
  const roomName = location.state.roomName;
  let array = location?.state?.filterDateHour;
  let ar = array[0]?.HourReadings;
  let arr = [].concat(ar).reverse();
  // console.log("this is the first array",arr[0])

  // console.log("This is the length of array",arr.length)
  // Get Total Values
  let arrLen = arr.length
  let totalT = arr[0]?.total - arr[arr.length - 1]?.total;
  let totalAvg = totalT/arrLen
  let linetemp = 0
  let addlinetemp = arr?.map((data)=>{
    return parseFloat(data?.linetemp)
  })
  for (var i in addlinetemp){
    linetemp +=addlinetemp[i]
  }
  let linetempavg = linetemp/arrLen
  let pressure = 0
  let addpressure = arr?.map((data)=>{
    return parseFloat(data?.pressure)
  })
  for (var i in addpressure){
    pressure +=addpressure[i]
  }
  let pressureavg = pressure/arrLen
  // console.log(linetempavg)


  const reportformat =
    "Lucky\t" + roomName + "\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="report" id="reportid">
          <Header roomName={roomName} />
          <div className="date">
            {arr[arr?.length-1]?.date}
          </div>
          <table className="tablemain" id="tablee">
            <thead className="tablehead">
              <tr className="tablerow">
                <th className="tableh">Time | Date</th>
                {arr[arr.length - 1]?.flow && (
                  <th className="tableh">Flow Kg</th>
                )}
                {arr[arr.length - 1]?.total && (
                  <th className="tableh">Total Flow Ton</th>
                )}
                {arr[arr.length - 1]?.linetemp && (
                  <th className="tableh">Line Temp °C</th>
                )}
                {arr[arr.length - 1]?.pressure && (
                  <th className="tableh">Pressure Psi</th>
                )}
              </tr>
            </thead>
            <tbody className="tablebody">
              {arr.map((ddd, i) => {
                return (
                  
                  <tr className="tablerow" key={i}>
                        <>
                    <td className="tabled" >
                      {ddd?.time} | {ddd?.date}
                    </td>
                    {ddd?.flow && (
                      <td className="tabled">
                        {parseInt(ddd?.totalHourReduction*1000).toLocaleString()}
                      </td>
                    )}
                    {arr[arr.length - 1]?.total && (
                      <td className="tabled">
                        {parseInt(ddd?.total).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.linetemp && (
                      <td className="tabled">
                        {parseInt(ddd?.linetemp).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.pressure && (
                      <td className="tabled">
                        {parseInt(ddd?.pressure).toLocaleString()}{" "}
                      </td>
                    )}
                    </>
                    </tr>
                    );
                  })}
                
              <tr className="tablerow">
                <th className="tableh tablehead">Avg | Total</th>
                {arr[arr.length - 1]?.total && (
                  <td className="tabled tablehead">
                    {parseInt(totalAvg * 1000).toLocaleString()}
                  </td>
                )}
                {arr[arr.length - 1]?.total && (
                  <td className="tabled tablehead">
                    {parseInt(totalT).toLocaleString()}
                  </td>
                )}
                {arr[arr.length - 1]?.linetemp && (
                  <td className="tabled tablehead">
                    {parseInt(linetempavg).toLocaleString()}
                  </td>
                )}
                {arr[arr.length - 1]?.pressure && (
                  <td className="tabled tablehead">
                    {parseInt(pressureavg).toLocaleString()}
                  </td>
                )}
                
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;
