import React from 'react'
import './Header.css'
// import hunch from '../../../Asserts/Logo/hunch.png'
// import { useLocation } from 'react-router-dom'

import MenuProvider from "react-flexible-sliding-menu";
import Menu from '../SlideableManu/Menu';
import SlideableManu1 from '../SlideableManu/SlideableManu1'

export default function Header(props) {
    // const location = useLocation()
    // let roomName = location?.state?.roomName
    // console.log("123",roomName)
    return (
        <>
            <div className="machineHeader">
                {/* <img className='machineimagelogo' src={hunch} alt="Uploading" /> */}
                <div className="sidemanubar">
                    {/* <MenuProvider MenuComponent={Menu}> */}
                        <MenuProvider MenuComponent={Menu} animation="push">
                        <SlideableManu1 />
                    </MenuProvider>
                </div>
                <div className="roomName">{props.roomName}</div>
                <div className="machineheading"><strong className='boldmachineheading'> MRS9000</strong> Monitoring & Reporting System</div>
                <div className="datetimeupdated">{props.timedate}</div>
            </div>
        </>
    )
}
